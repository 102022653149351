import React from 'react';

export default class UnauthNotify extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      show: props.immediatelyShow || false
    }
  }

  componentDidMount() {
    if (!this.props.isPublic) buildsite.api().on("unauth", this.unauth);
    buildsite.api().on("session_reset", this.sessionReset);
  }

  componentWillUnmount() {
    if (!this.props.isPublic) buildsite.api().removeListener("unauth", this.unauth);
    buildsite.api().removeListener("session_reset", this.sessionReset);
  }

  sessionReset = id => {
    let user = buildsite.currentUser();

    if ((((user || {}).data || {}).user_id || -1) == id) {
      this.showModal();
    }
  }

  unauth = () => {
    let user = buildsite.currentUser();

    if (user && user.data && user.data.user_id) {
      this.showModal();
    }
  }

  showModal = () => {
    document.body.classList.add("modal-open");
    document.addEventListener("keydown", this.handleEscKey, false);
    document.addEventListener("click", this.handleClickOutside);
    this.setState({show: true});
  }

  hideModal = () => {
    document.removeEventListener("keydown", this.handleEscKey, false);
    document.removeEventListener("click", this.handleClickOutside);
    document.body.classList.remove("modal-open");
    this.setState({show: false});
    this.clickReload();
  }

  handleClickOutside = e => {
    if (e.target.id == 'unauthNotify') this.hideModal();
  }

  handleEscKey = e => {
    if (e.keyCode == 27) this.hideModal();
  }

  clickReload = e => {
    e && e.preventDefault();
    window.location.reload();
  }

  renderHeader = () => {
    if (this.props.isPublic) return (
      <div className="modal-header">
        <a href="#" className="modal-header__close-modal" onClick={this.clickReload} />
        <h1 className="modal-header__title h1">Please log in again.</h1>
      </div>
    );
    return (
      <div className="modal-header">
        <a href="#" onClick={this.clickReload} aria-label="Close" className="close-icon" />
        <h4 className="modal-title">Please log in again.</h4>
      </div>
    );
  }

  renderBody = () => {
    if (this.props.isPublic) return (
      <div className="modal-body no_max_width centered">
        <p className="no_margin_bottom">Your session has expired or you have logged out in another tab.</p>
        <p>Please <a href="/login" className="dark_colored "><b>log in</b></a> again when you need it.</p>
      </div>
    );
    return (
      <div className="modal-body">
        <p>Your session has expired or you have logged out in another tab.</p>
        <p>Please <a href="/login">log in again</a> when you need it.</p>
      </div>
    );
  }

  renderFooter = () => {
    if (this.props.isPublic) return (
      <div className="modal-footer centered">
        <input type="submit" autoFocus value="Reload" className="__btn _black" />
      </div>
    );
    return (
      <div className="modal-footer">
        <input type="submit" autoFocus value="Reload" className="btn-default btn-blue" />
      </div>
    );
  }

  render() {
    return (
      <div id="unauthNotify" className={classNames("modal custom-modal", {"modal-open": this.state.show})}>
          <div className="modal-dialog">
            <form className="modal-content" onSubmit={this.clickReload}>
              {this.renderHeader()}
              {this.renderBody()}
              {this.renderFooter()}
            </form>
          </div>
        </div>
    );
  }
}
