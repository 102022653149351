import React from 'react';
import UnauthNotify from '../../ui-components/unauth-notify';

export default class WithUser extends React.Component{
  state = {
    gotUser: false
  }

  componentDidMount() {
    buildsite.user().open(this.updateUser)
      .fail(() => {
        this.setState({unauth: true})
        this.updateUser()
      }
    );
  }

  componentWillUnmount() {
    buildsite.user().close(this.updateUser);
  }

  updateUser = (data) => {
    if (!this.state.gotUser) this.setState({gotUser: true});
  }

  render () {
    if (!this.state.gotUser) {
      return (
        <div className="userLoading" />
      );
    }

    if (this.state.unauth && this.props.subui) return <UnauthNotify immediatelyShow />

    return (
      <>
        {this.props.children}
      </>
    );
  }
}
